import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ limage, dimage, simage }) => {
  return (
    <Link to={process.env.PUBLIC_URL + "/"}>
      <div className="light-version-logo-container">
        <img
          className="light-version-logo"
          src={process.env.PUBLIC_URL + limage}
          alt="logo"
        />
        BrainBoom
      </div>
      <img
        className="dark-version-logo"
        src={process.env.PUBLIC_URL + dimage}
        alt="logo"
      />
      <div className="sticky-logo-container">
        <img
          className="sticky-logo"
          src={process.env.PUBLIC_URL + simage}
          alt="logo"
        />
        BrainBoom
      </div>
    </Link>
  );
};

export default Logo;
